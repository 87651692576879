import { __awaiter, __generator } from "tslib";
import { Validators } from '@angular/forms';
import { GoogleLoginProvider } from 'angularx-social-login';
import { combineLatest } from 'rxjs';
var LoginComponent = /** @class */ (function () {
    function LoginComponent(authenticationService, userService, snackBar, router, formBuilder, authService, activatedRoute) {
        this.authenticationService = authenticationService;
        this.userService = userService;
        this.snackBar = snackBar;
        this.router = router;
        this.formBuilder = formBuilder;
        this.authService = authService;
        this.activatedRoute = activatedRoute;
        this.loading = false;
        this.isSubmitted = false;
        this.isSubmittedForgot = false;
        this.showPassword = false;
        this.isLogged = false;
        this.invalidData = false;
        this.loginForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required, Validators.minLength(8)]],
        });
    }
    Object.defineProperty(LoginComponent.prototype, "formControls", {
        get: function () {
            return this.loginForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    LoginComponent.prototype.login = function () {
        var _this = this;
        this.isSubmitted = true;
        if (this.loginForm.invalid) {
            return;
        }
        this.loading = true;
        combineLatest([
            this.activatedRoute.queryParams,
            this.authenticationService.login(this.loginForm.value),
        ]).subscribe(function (results) {
            var data = results[1];
            var returnUrl = results[0].returnUrl || '/';
            if (data && data.id) {
                _this.setSessionData(data);
                _this.router.navigateByUrl(returnUrl);
            }
            _this.loading = false;
        }, function (error) {
            _this.invalidData = true;
            _this.loading = false;
            console.error(error);
        });
    };
    LoginComponent.prototype.setShowPassword = function () {
        if (this.loginForm.controls.password.value) {
            this.showPassword = !this.showPassword;
        }
        else {
            this.showPassword = false;
        }
    };
    LoginComponent.prototype.setSessionData = function (data) {
        this.authenticationService.setSessionToken(data.auth_Token);
        this.authenticationService.setRefreshToken(data.refresh_Token);
        var userData = this.authenticationService.decodePayloadJWT();
        this.authenticationService.setUserProfile(userData);
        localStorage.setItem('is_internal_user', 'true');
        this.authenticationService.setExpirationDate(data.expires_In);
        localStorage.setItem('user', JSON.stringify({
            id: userData.id,
            firstName: userData.firstName,
            lastName: userData.lastName,
            email: userData.email,
            storeCode: userData.storeCode,
            countryCode: userData.countryCode,
            isAdmin: userData.isAdmin,
        }));
        this.authenticationService.isLoggedIn$.next(true);
    };
    LoginComponent.prototype.loginWithGoogle = function () {
        return __awaiter(this, void 0, void 0, function () {
            var result, externalLogin, e_1;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loginForm.reset();
                        this.isSubmitted = false;
                        this.loading = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.authService.signIn(GoogleLoginProvider.PROVIDER_ID)];
                    case 2:
                        result = _a.sent();
                        console.log(result);
                        externalLogin = { providerId: result.provider, idToken: result.idToken };
                        this.authenticationService.loginWithGoogleSingin(externalLogin).subscribe(function (response) {
                            if (response && response.id) {
                                _this.authenticationService.setSessionToken(response.auth_Token);
                                _this.authenticationService.setRefreshToken(response.refresh_Token);
                                var userData = _this.authenticationService.decodePayloadJWT();
                                _this.authenticationService.setUserProfile(userData);
                                localStorage.setItem('is_internal_user', 'true');
                                _this.authenticationService.setExpirationDate(response.expires_In);
                                localStorage.setItem('user', JSON.stringify({
                                    id: userData.id,
                                    firstName: userData.firstName,
                                    lastName: userData.lastName,
                                    email: userData.email,
                                    storeCode: userData.storeCode,
                                    countryCode: userData.countryCode,
                                    isAdmin: userData.isAdmin,
                                }));
                                _this.authenticationService.isLoggedIn$.next(true);
                                _this.router.navigateByUrl('/oms/info');
                            }
                        }, function (error) {
                            _this.invalidData = true;
                            console.log(error);
                        }, function () { return (_this.loading = false); });
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        this.loading = false;
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    LoginComponent.prototype.setResetPassword = function () {
        var _this = this;
        this.isSubmittedForgot = true;
        var emailControl = this.loginForm.controls['email'];
        if (emailControl.valid) {
            var email = emailControl.value;
            this.userService.setResetPassword(undefined, email).subscribe(function (response) {
                _this.snackBar.open('Contraseña restablecida, verifique su casilla de mail', 'Cerrar', {
                    duration: 5000,
                });
            }, function (error) {
                _this.snackBar.open('Surgió un error, no se ha restrablecido la contraseña', 'Cerrar', {
                    duration: 5000,
                });
            });
        }
    };
    return LoginComponent;
}());
export { LoginComponent };
