//cdk
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { MatSpinner } from '@angular/material';
//rxjs
import { Subject } from 'rxjs';
import { scan, map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/cdk/overlay";
import * as i2 from "@angular/material/snack-bar";
var UiService = /** @class */ (function () {
    function UiService(overlay, snackBar) {
        var _this = this;
        this.overlay = overlay;
        this.snackBar = snackBar;
        this.spinnerTopRef = this.cdkSpinnerCreate();
        this.spin$ = new Subject();
        this.spin$
            .asObservable()
            .pipe(map(function (val) { return val ? 1 : -1; }), scan(function (acc, one) { return (acc + one) >= 0 ? acc + one : 0; }, 0))
            .subscribe(function (res) {
            if (res === 1) {
                _this.showSpinner();
            }
            else if (res == 0) {
                _this.spinnerTopRef.hasAttached() ? _this.stopSpinner() : null;
            }
        });
    }
    UiService.prototype.cdkSpinnerCreate = function () {
        return this.overlay.create({
            hasBackdrop: false,
            backdropClass: 'dark-backdrop',
            positionStrategy: this.overlay.position()
                .global()
                .centerHorizontally()
                .centerVertically()
        });
    };
    UiService.prototype.showSpinner = function () {
        var _this = this;
        if (!this.spinnerTopRef.hasAttached()) {
            this.spinnerTopRef.attach(new ComponentPortal(MatSpinner));
            setTimeout(function () {
                _this.stopSpinner();
            }, 12000);
        }
    };
    UiService.prototype.stopSpinner = function () {
        this.spinnerTopRef.detach();
    };
    UiService.prototype.openSnackBar = function (message, action) {
        this.snackBar.open(message, action, {
            duration: 5000
        });
    };
    UiService.prototype.displayErrorMessage = function (exception, action, article, component) {
        if (exception && exception.error && exception.error.error) {
            this.openSnackBar("" + exception.error.error, '');
        }
        else {
            this.openSnackBar("Ocurri\u00F3 un error al " + action + " " + article + " " + component, '');
        }
    };
    UiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UiService_Factory() { return new UiService(i0.ɵɵinject(i1.Overlay), i0.ɵɵinject(i2.MatSnackBar)); }, token: UiService, providedIn: "root" });
    return UiService;
}());
export { UiService };
