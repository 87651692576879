import { OnInit } from '@angular/core';
var CountryComponent = /** @class */ (function () {
    function CountryComponent(authenticationService, commonService) {
        this.authenticationService = authenticationService;
        this.commonService = commonService;
        this.countryList = [
            { code: 'AR', name: 'Argentina', icon: 'icon-flag-arg' },
            { code: 'CO', name: 'Colombia', icon: 'icon-flag-col' },
            { code: 'PPCO', name: 'PetPlus', icon: 'icon-flag-ppco' },
        ];
        this.selectedCountry = { code: 'AR', name: 'Argentina', icon: 'icon-flag-arg' };
        this.disabledSelectCountry = true;
    }
    Object.defineProperty(CountryComponent.prototype, "userProfile$", {
        get: function () {
            return this.authenticationService.userProfile$;
        },
        enumerable: true,
        configurable: true
    });
    CountryComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.userProfile$.subscribe(function (profile) {
            if (profile)
                _this.setDefaultCountry(profile.countryCode);
        });
        this.commonService.selectedCountry$.subscribe(function (country) {
            if (!country) {
                _this.setDefaultCountry();
            }
            else {
                _this.commonService.setCookie('country', country.code, 120);
                _this.selectedCountry = country;
            }
        });
    };
    CountryComponent.prototype.selectCountry = function (country) {
        this.commonService.setSelectedCountry(country);
    };
    CountryComponent.prototype.setDefaultCountry = function (countryCode) {
        // let countryCode = this.userProfile$?.value?.countryCode;
        if (!countryCode) {
            countryCode = this.commonService.getCookie('country');
            this.disabledSelectCountry = false;
        }
        if (countryCode)
            this._setCountry(countryCode);
        else
            this.commonService.setCookie('country', this.selectedCountry.code, 120);
    };
    CountryComponent.prototype._setCountry = function (code) {
        if (code === 'AR') {
            this.selectedCountry = { code: 'AR', name: 'Argentina', icon: 'icon-flag-arg' };
        }
        else if (code === 'CO') {
            this.selectedCountry = { code: 'CO', name: 'Colombia', icon: 'icon-flag-col' };
        }
        else if (code === 'PPCO') {
            this.selectedCountry = { code: 'PPCO', name: 'PetPlus', icon: 'icon-flag-ppco' };
        }
    };
    return CountryComponent;
}());
export { CountryComponent };
