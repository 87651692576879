import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
// http://localhost:4200/public/transfer-qr/1
var TransferQRComponent = /** @class */ (function () {
    function TransferQRComponent(transferService, activatedRoute, storeService) {
        var _this = this;
        this.transferService = transferService;
        this.activatedRoute = activatedRoute;
        this.storeService = storeService;
        this.transferId = null;
        this.transfer = null;
        this.isLoading = false;
        this.errorMsj = '';
        this.qrTransferUrl = '';
        this.activatedRoute.params.subscribe(function (params) {
            if (params.id) {
                _this.transferId = Number(params.id);
                _this.qrTransferUrl = window.location.origin + "/transfers/transfer-accept/" + _this.transferId;
            }
        });
    }
    TransferQRComponent.prototype.ngOnInit = function () {
        if (this.transferId) {
            this.getDataPage();
            console.log('data page');
        }
    };
    TransferQRComponent.prototype.getDataPage = function () {
        var _this = this;
        this.isLoading = true;
        this.errorMsj = '';
        console.log('this.transfersService');
        this.transferService.getTransferOrderInfo(this.transferId)
            .pipe(finalize(function () { return (_this.isLoading = false); }))
            .subscribe(function (result) {
            _this.transfer = result;
            _this.transfer.sourceStoreCode = _this.storeService.getStoreName(_this.transfer.sourceStoreCode),
                _this.transfer.destinationStoreCode = _this.storeService.getStoreName(_this.transfer.destinationStoreCode),
                console.log('tranferencia obtenida ' + result);
        }, function (error) {
            //Error callback
            // console.error('error caught in component ' + error.status);
            //if (error.status == 400)
            //this.errorMsj = 'El pedido es de retiro en tienda';
            if (error.status == 404)
                _this.errorMsj = 'No se encuentra la trasferencia';
            throw error;
        });
    };
    TransferQRComponent.prototype.getSkus = function () {
        var _a, _b;
        if (((_a = this.transfer) === null || _a === void 0 ? void 0 : _a.transferItemsSkus.length) > 0) {
            return (_b = this.transfer) === null || _b === void 0 ? void 0 : _b.transferItemsSkus.join(', ');
        }
        return '';
    };
    return TransferQRComponent;
}());
export { TransferQRComponent };
