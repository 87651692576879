import { Routes } from '@angular/router';
import { AuthGuard } from '../guards/auth.guard';
import { HomeComponent } from './home.component';
import { ProfileComponent } from '../profile/profile.component';
var ɵ0 = function () { return import("./../security/security.module.ngfactory").then(function (m) { return m.SecurityModuleNgFactory; }); }, ɵ1 = function () { return import("./../supplying/supplying.module.ngfactory").then(function (m) { return m.SupplyingModuleNgFactory; }); }, ɵ2 = function () { return import("./../oms/oms.module.ngfactory").then(function (m) { return m.OmsModuleNgFactory; }); }, ɵ3 = function () { return import("./../wms/wms.module.ngfactory").then(function (m) { return m.WmsModuleNgFactory; }); }, ɵ4 = function () { return import("./../sales/sales.module.ngfactory").then(function (m) { return m.SalesModuleNgFactory; }); }, ɵ5 = function () { return import("./../products-lite/products-lite.module.ngfactory").then(function (m) { return m.ProductsLiteModuleNgFactory; }); }, ɵ6 = function () { return import("./../mailling/mailling.module.ngfactory").then(function (m) { return m.MaillingModuleNgFactory; }); }, ɵ7 = function () { return import("./../stores/stores.module.ngfactory").then(function (m) { return m.StoresModuleNgFactory; }); }, ɵ8 = function () { return import("./../landing/landing.module.ngfactory").then(function (m) { return m.LandingModuleNgFactory; }); }, ɵ9 = function () { return import("./../logs/logs.module.ngfactory").then(function (m) { return m.LogsModuleNgFactory; }); }, ɵ10 = function () { return import("./../transfers/transfers.module.ngfactory").then(function (m) { return m.TransfersModuleNgFactory; }); }, ɵ11 = function () { return import("./../clients/clients.module.ngfactory").then(function (m) { return m.ClientsModuleNgFactory; }); }, ɵ12 = function () { return import("./../notifications/notifications.module.ngfactory").then(function (m) { return m.NotificationsModuleNgFactory; }); };
var routes = [
    {
        path: '', canActivate: [AuthGuard], component: HomeComponent, children: [
            { path: '', redirectTo: 'oms/info', pathMatch: 'full' },
            { path: 'profile', canActivate: [AuthGuard], component: ProfileComponent },
            { path: 'security', canActivate: [AuthGuard], loadChildren: ɵ0 },
            { path: 'supplying', canActivate: [AuthGuard], loadChildren: ɵ1 },
            { path: 'oms', canActivate: [AuthGuard], loadChildren: ɵ2 },
            { path: 'wms', canActivate: [AuthGuard], loadChildren: ɵ3 },
            { path: 'sales', canActivate: [AuthGuard], loadChildren: ɵ4 },
            { path: 'products', canActivate: [AuthGuard], loadChildren: ɵ5 },
            { path: 'mailling', canActivate: [AuthGuard], loadChildren: ɵ6 },
            { path: 'stores', canActivate: [AuthGuard], loadChildren: ɵ7 },
            { path: 'landing', canActivate: [AuthGuard], loadChildren: ɵ8 },
            { path: 'logs', canActivate: [AuthGuard], loadChildren: ɵ9 },
            { path: 'transfers', canActivate: [AuthGuard], loadChildren: ɵ10 },
            { path: 'clientes', canActivate: [AuthGuard], loadChildren: ɵ11 },
            { path: 'notifications', canActivate: [AuthGuard], loadChildren: ɵ12 }
        ]
    },
];
var HomeRoutingModule = /** @class */ (function () {
    function HomeRoutingModule() {
    }
    return HomeRoutingModule;
}());
export { HomeRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12 };
