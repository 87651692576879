import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var TransferOmsService = /** @class */ (function () {
    function TransferOmsService(http) {
        this.http = http;
        this.baseUrl = environment.omsUrl;
    }
    TransferOmsService.prototype.getTransferOrderInfo = function (id) {
        var queryUrl = this.baseUrl + "api/services/app/Transfer/GetTransferOrderInfo?id=" + id;
        return this.http
            .get(queryUrl)
            .pipe(map(function (x) { return x.result ? x.result : null; }));
    };
    TransferOmsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TransferOmsService_Factory() { return new TransferOmsService(i0.ɵɵinject(i1.HttpClient)); }, token: TransferOmsService, providedIn: "root" });
    return TransferOmsService;
}());
export { TransferOmsService };
var TransferOrderInfoDto = /** @class */ (function () {
    function TransferOrderInfoDto() {
    }
    return TransferOrderInfoDto;
}());
export { TransferOrderInfoDto };
