import { LogoutDialogComponent } from './logout-dialog/logout-dialog.component';
var UserProfileComponent = /** @class */ (function () {
    function UserProfileComponent(authenticationService, dialog) {
        this.authenticationService = authenticationService;
        this.dialog = dialog;
    }
    Object.defineProperty(UserProfileComponent.prototype, "userProfile$", {
        get: function () { return this.authenticationService.userProfile$; },
        enumerable: true,
        configurable: true
    });
    UserProfileComponent.prototype.openLogoutDialog = function () {
        var _this = this;
        var dialogRef = this.dialog.open(LogoutDialogComponent, {
            width: '22.857em',
            data: {}
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result === true) {
                _this._logout();
            }
        });
    };
    UserProfileComponent.prototype._logout = function () {
        this.authenticationService.logout();
    };
    return UserProfileComponent;
}());
export { UserProfileComponent };
