import { __read, __spread } from "tslib";
import { Injector } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HelperService } from '../oms/services/helper.service';
import { UserService } from './user.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./common.service";
import * as i3 from "./localization.service";
var StoreService = /** @class */ (function () {
    function StoreService(httpClient, commonService, localizationService, injector) {
        this.httpClient = httpClient;
        this.commonService = commonService;
        this.localizationService = localizationService;
        this.BASE_URL = environment.storesUrl;
        this._stores = [];
        this.STORE_KEY = 'CURRENT_STORE';
        this.COUNTRY_KEY = 'country';
        this.userService = injector.get(UserService);
    }
    StoreService.prototype.setCurrentStore = function (storeCode) {
        HelperService.storeOnLocalStorage(this.STORE_KEY, storeCode);
    };
    StoreService.prototype.getStore = function (storeCode) {
        if (storeCode === void 0) { storeCode = null; }
        if (storeCode == null) {
            storeCode = this.getCurrentStore();
        }
        return this._stores.find(function (x) { return x.storeCode === storeCode; });
    };
    StoreService.prototype.currentStoreIsWarehouse = function () {
        var current = this.getStore(this.getCurrentStore());
        if (!current)
            return false;
        return current.isWarehouse;
    };
    StoreService.prototype.getStores = function (countryCode) {
        if (countryCode === void 0) { countryCode = null; }
        var stores = [];
        var currentCountry = countryCode || this.commonService.getCookie(this.COUNTRY_KEY);
        if (currentCountry) {
            stores = this._stores.filter(function (x) { return x.countryCode === currentCountry; });
        }
        else {
            stores = this._stores;
        }
        stores = __spread([
            {
                name: this.localizationService.localize('allStores'),
                id: '',
                isHub: false,
            }
        ], stores.map(function (x) { return ({
            id: x.storeCode,
            name: x.name,
            isHub: x.isHub,
            addressState: x.addressState,
        }); }));
        return stores;
    };
    StoreService.prototype.getStoresAvailablesForSales = function (countryCode) {
        if (countryCode === void 0) { countryCode = null; }
        var stores = [];
        var currentCountry = countryCode || this.commonService.getCookie(this.COUNTRY_KEY);
        if (currentCountry) {
            stores = this._stores.filter(function (x) { return x.countryCode === currentCountry && x.availableForSales; });
        }
        else {
            stores = this._stores.filter(function (x) { return x.availableForSales; });
        }
        stores = __spread(stores.map(function (x) { return ({
            id: x.storeCode,
            name: x.name,
            isHub: x.isHub,
            addressState: x.addressState,
        }); }));
        return stores;
    };
    StoreService.prototype.getStoresByCity = function (city) {
        return this._stores.filter(function (x) { return x.addressState === city; }).map(function (x) { return x.storeCode; });
    };
    StoreService.prototype.getCurrentStore = function () {
        var storeCode = HelperService.getValueFromLocalStorage(this.STORE_KEY);
        if (this.userService) {
            var user = this.userService.getUserProfileFromLocal();
            storeCode = user.storeCode || storeCode || '';
        }
        return storeCode;
    };
    StoreService.prototype.isStoreSelected = function () {
        return this.getCurrentStore() ? this.getCurrentStore() !== '' : false;
    };
    StoreService.prototype.getStoreName = function (code) {
        var store = this.getStores().find(function (x) { return x.id === code; });
        if (store) {
            return store.name;
        }
        else {
            return '';
        }
    };
    StoreService.prototype.isStoreHub = function (code) {
        var store = this.getStores().find(function (x) { return x.id === code; });
        return store ? store.isHub : false;
    };
    StoreService.prototype.getStoreNameByErpCode = function (code) {
        var stores = [];
        var currentCountry = this.commonService.getCookie(this.COUNTRY_KEY);
        if (currentCountry) {
            stores = this._stores.filter(function (x) { return x.countryCode === currentCountry; });
        }
        else {
            stores = this._stores;
        }
        return (stores.find(function (x) { return x.erpCode == code; }) || {}).name || code;
    };
    StoreService.prototype.getStoreErpCode = function (code) {
        var store = this._stores.find(function (x) { return x.storeCode === code; });
        return store ? store.erpCode : code;
    };
    StoreService.prototype.getStoreEmail = function (code) {
        var _a;
        return (_a = this._stores.find(function (x) { return x.storeCode === code; })) === null || _a === void 0 ? void 0 : _a.email;
    };
    StoreService.prototype.load = function () {
        var _this = this;
        console.log('load stores ...');
        return this.httpClient
            .get(this.BASE_URL + "/api/services/app/Store/GetAllCountries?MaxResultCount=1000")
            .pipe(catchError(function (e) { return of([]); }))
            .pipe(map(function (r) { return (r.result ? r.result.items : []); }))
            .pipe(tap(function (stores) {
            _this._stores = stores.sort(function (n1, n2) {
                if (n1.name > n2.name) {
                    return 1;
                }
                if (n1.name < n2.name) {
                    return -1;
                }
                return 0;
            });
        }));
    };
    StoreService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StoreService_Factory() { return new StoreService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.CommonService), i0.ɵɵinject(i3.LocalizationService), i0.ɵɵinject(i0.INJECTOR)); }, token: StoreService, providedIn: "root" });
    return StoreService;
}());
export { StoreService };
