import { OnInit } from '@angular/core';
var NotificationsComponent = /** @class */ (function () {
    function NotificationsComponent(dialog, _notificationsService, 
    // tslint:disable-next-line:variable-name
    router, uiService, localizationService) {
        this.dialog = dialog;
        this._notificationsService = _notificationsService;
        this.router = router;
        this.uiService = uiService;
        this.localizationService = localizationService;
    }
    Object.defineProperty(NotificationsComponent.prototype, "notifications$", {
        get: function () {
            return this._notificationsService.notifications$;
        },
        enumerable: true,
        configurable: true
    });
    NotificationsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.notifications$
            .subscribe({
            next: function (notifResponse) {
                //this.count = (notifResponse ?  notifResponse.notificationsList.filter(y => !y.readed).length : 0);
                //console.log('obtenidas', notifResponse);
                if (notifResponse.notificationsList.length > 0) {
                    var lastNotificationID = window.localStorage.getItem('last_notification_id');
                    if (lastNotificationID) {
                        var newNoti = (notifResponse.notificationsList[0].id > Number(lastNotificationID)) ? true : false;
                        if (newNoti)
                            _this.playNotification();
                    }
                    window.localStorage.setItem('last_notification_id', notifResponse.notificationsList[0].id.toString());
                }
            },
            error: function (err) { return console.error('get notifications error: ' + err); },
            complete: function () { return console.log('get notifications complete'); }
        });
    };
    NotificationsComponent.prototype.playNotification = function () {
        var audio = new Audio();
        audio.src = 'assets/sounds/notification1.wav';
        audio.load();
        audio.play();
    };
    NotificationsComponent.prototype.goToNotificationList = function () {
        this.router.navigate(['/notifications']);
    };
    NotificationsComponent.prototype.readAll = function () {
        var _this = this;
        var msjOK = this.localizationService.localize('MarkAsReadedSuccessful', null);
        var msjEr = this.localizationService.localize('MarkAsReadedError', null);
        this._notificationsService
            .markAsReaded([])
            .subscribe(function () {
            _this.uiService.openSnackBar(msjOK, null);
            _this._notificationsService.getAllNotifications();
        }, function () { return _this.uiService.openSnackBar(msjEr, null); });
    };
    return NotificationsComponent;
}());
export { NotificationsComponent };
