import { PipeTransform } from '@angular/core';
var LocalizePipe = /** @class */ (function () {
    function LocalizePipe(localizationService) {
        this.localizationService = localizationService;
    }
    LocalizePipe.prototype.transform = function (key) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        return this.localizationService.localize(key, args);
    };
    return LocalizePipe;
}());
export { LocalizePipe };
var LocalizeMakedPipe = /** @class */ (function () {
    function LocalizeMakedPipe(localizationService) {
        this.localizationService = localizationService;
    }
    LocalizeMakedPipe.prototype.transform = function (key) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        return this.localizationService.localizeMarkedText(key, args);
    };
    return LocalizeMakedPipe;
}());
export { LocalizeMakedPipe };
