import { NameValueDto } from '../oms-lite/services/service-proxy/service-proxies';
import { environment } from 'src/environments/environment';
import { map, tap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var LocalizationService = /** @class */ (function () {
    function LocalizationService(httpClient) {
        this.httpClient = httpClient;
        this.baseUrl = environment.settingsUrl;
        this.localizedStrings = [];
        if (!this.getCulture()) {
            this.setCulture('es-CO');
        }
    }
    LocalizationService.prototype.setCulture = function (culture) {
        localStorage.setItem('culture', culture);
    };
    LocalizationService.prototype.getCulture = function () {
        return localStorage.getItem('culture');
    };
    LocalizationService.prototype.load = function () {
        var _this = this;
        var culture = this.getCulture();
        return this.httpClient
            .get(this.baseUrl + "/api/services/app/Localization/GetAllByCulture?culture=" + culture)
            .pipe(catchError(function (e) { return of([]); }))
            .pipe(map(function (r) { return r.result || []; }))
            .pipe(tap(function (items) {
            items.forEach(function (element) {
                _this.localizedStrings.push(new NameValueDto({ name: element.key, value: element.text }));
            });
        }));
    };
    LocalizationService.prototype.localize = function (key) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        var item = this.localizedStrings
            .find(function (nameValue) { return key != null && key != undefined && nameValue.name.toLowerCase() === key.toLowerCase(); });
        if (!item) {
            return key;
        }
        return this.formatString(item.value, args);
    };
    LocalizationService.prototype.localizeMarkedText = function (text) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        var newText = "";
        var reBrackets = /\[(.*?)\]/g;
        var found;
        while (found = reBrackets.exec(text)) {
            var key = found[1];
            newText = text.replace("[" + key + "]", this.localize(key));
        }
        ;
        if (newText == "")
            newText = text;
        return newText;
    };
    LocalizationService.prototype.formatString = function (str) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        for (var i = 0; i < args.length; i++) {
            var placeHolder = '{' + i + '}';
            str = str.replace(placeHolder, args[i]);
        }
        return str;
    };
    LocalizationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LocalizationService_Factory() { return new LocalizationService(i0.ɵɵinject(i1.HttpClient)); }, token: LocalizationService, providedIn: "root" });
    return LocalizationService;
}());
export { LocalizationService };
