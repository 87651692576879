import localeAR from '@angular/common/locales/es-AR';
import localeCO from '@angular/common/locales/es-CO';
import { registerLocaleData } from '@angular/common';
import { LocalizationService } from './services/localization.service';
import { StoreService } from './services/stores.service';
import { forkJoin } from 'rxjs';
import { UserService } from './services/user.service';
import { SocialAuthServiceConfig } from 'angularx-social-login';
import { GoogleLoginProvider } from 'angularx-social-login';
import { environment } from '../environments/environment';
registerLocaleData(localeAR, 'es-AR');
registerLocaleData(localeCO, 'es-CO');
var ɵ0 = function (locService) { return locService.getCulture(); }, ɵ1 = {
    autoLogin: false,
    providers: [
        {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(environment.AUTH_CONFIG.clientId, {
                scope: 'email',
                plugin_name: 'puppis-demo-login'
            })
        }
    ]
};
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export function initialize(localizationService, storeService, userService) {
    return function () { return forkJoin([localizationService.load(), storeService.load(), userService.load()]).toPromise(); };
}
export { ɵ0, ɵ1 };
