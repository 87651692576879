import { timer, Subject, of, BehaviorSubject } from 'rxjs';
import { concatMap, finalize, map, retry, shareReplay, takeUntil, takeWhile, tap } from 'rxjs/operators';
import * as moment from 'moment';
import { ConfirmationDialogComponent } from '../shared/components/confirmation-dialog/confirmation-dialog.component';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/material/dialog";
import * as i3 from "./ui.service";
var ProcessService = /** @class */ (function () {
    function ProcessService(httpClient, dialog, ui) {
        var _this = this;
        this.httpClient = httpClient;
        this.dialog = dialog;
        this.ui = ui;
        this.polledProcesses$ = {};
        this.cancelationToken$ = new Subject();
        this.isProcessing$ = new Subject();
        this.finishedProcessesKey = 'finished_processes';
        var finishedProcessesJson = localStorage.getItem(this.finishedProcessesKey);
        if (finishedProcessesJson) {
            this.finishedProcess$ = new BehaviorSubject(JSON.parse(finishedProcessesJson));
        }
        else {
            this.finishedProcess$ = new BehaviorSubject([]);
        }
        this.cancelationToken$.subscribe(function (processUrl) {
            if (processUrl) {
                delete _this.polledProcesses$[processUrl];
            }
            else {
                _this.polledProcesses$ = {};
            }
            _this.isProcessing$.next(Object.keys(_this.polledProcesses$).length > 0);
        });
    }
    ProcessService.prototype.getProcessByUrl = function (url) {
        return this.httpClient.get(url).pipe(shareReplay(1), map(this.mapResponse));
    };
    ProcessService.prototype.getProcess = function (url, interval) {
        var _this = this;
        if (interval === void 0) { interval = 5000; }
        this.isProcessing$.next(true);
        this.polledProcesses$[url] = this.httpClient.get(url).pipe(map(this.mapResponse));
        return timer(0, interval)
            .pipe(concatMap(function () { return _this.polledProcesses$[url]; }), takeWhile(function (x) {
            return x.status !== 'Finished' &&
                x.status !== 'Error' &&
                x.status !== 'Stopped';
        }, true), takeUntil(this.cancelationToken$), retry(3), shareReplay(1), tap(function (process) { return _this.addFinishedProcess(process, url); }), finalize(function () { return _this.cancelationToken$.next(url); }));
    };
    ProcessService.prototype.getProcessState = function (url) {
        return this.getProcess(url).pipe(map(function (x) { return x.status; }));
    };
    ProcessService.prototype.getProcessOutput = function (url, interval) {
        if (interval === void 0) { interval = 5000; }
        return this.getProcess(url, interval)
            .pipe(map(function (x) {
            if (x.status === 'Error') {
                throw Error(x.output);
            }
            return x;
        })).pipe(map(function (x) { return x.output; }));
    };
    ProcessService.prototype.getProcessError = function (url) {
        return this.getProcess(url).pipe(map(function (x) { return x.output; }));
    };
    ProcessService.prototype.cancelIfProcessExists = function () {
        var _this = this;
        if (Object.keys(this.polledProcesses$).length > 0) {
            return this.dialog.open(ConfirmationDialogComponent, {
                data: {
                    title: 'Existe un proceso en ejecución. ¿Desea cancelarlo?',
                    btnPrimaryText: 'Si',
                    btnSecondaryText: 'No'
                }
            }).afterClosed().pipe(map(function (result) {
                if (result === 'Confirm') {
                    _this.cancelationToken$.next();
                    return true;
                }
                return false;
            }));
        }
        return of(true);
    };
    ProcessService.prototype.IfProcessExists = function () {
        if (Object.keys(this.polledProcesses$).length > 0) {
            return this.dialog.open(ConfirmationDialogComponent, {
                data: {
                    title: 'Ya existe un proceso en ejecución.',
                    btnPrimaryText: 'Ok'
                }
            }).afterClosed().pipe(map(function (result) {
                return false;
            }));
        }
        return of(true);
    };
    ProcessService.prototype.addFinishedProcess = function (process, url) {
        if (moment().diff(process.creationTime, 'minutes') > 30) {
            process.status = 'Stopped';
            process.output = 'Process timedout.';
            process.icon = 'highlight_off';
            this.cancelationToken$.next(url);
        }
        if (process.status !== 'Pending' && process.status !== 'Running') {
            var finishedProcessesJson = localStorage.getItem(this.finishedProcessesKey);
            var finishedProcesses = [];
            if (finishedProcessesJson) {
                finishedProcesses = JSON.parse(finishedProcessesJson);
            }
            process.id = this.getMaxOfArray(finishedProcesses.map(function (x) { return x.id; })) + 1;
            finishedProcesses.push(process);
            localStorage.setItem(this.finishedProcessesKey, JSON.stringify(finishedProcesses));
            this.finishedProcess$.next(finishedProcesses);
        }
    };
    ProcessService.prototype.removeFinishedProcess = function (id) {
        var finishedProcessesJson = localStorage.getItem(this.finishedProcessesKey);
        var finishedProcesses = [];
        if (finishedProcessesJson) {
            finishedProcesses = JSON.parse(finishedProcessesJson);
        }
        var processToRemoveIndex = finishedProcesses.findIndex(function (x) { return x.id === id; });
        if (processToRemoveIndex >= 0) {
            finishedProcesses.splice(processToRemoveIndex, 1);
            localStorage.setItem(this.finishedProcessesKey, JSON.stringify(finishedProcesses));
            this.finishedProcess$.next(finishedProcesses);
        }
    };
    ProcessService.prototype.clearFinishedProcesses = function () {
        localStorage.removeItem(this.finishedProcessesKey);
        this.finishedProcess$.next([]);
    };
    ProcessService.prototype.mapResponse = function (response) {
        var result = response.result;
        switch (result.status) {
            case 'Finished':
                result.icon = 'done';
                break;
            case 'Running':
                result.icon = 'settings';
                break;
            case 'Error':
                result.icon = 'cancel';
                break;
            case 'Stopped':
                result.icon = 'highlight_off';
                break;
            default:
                result.icon = 'schedule';
                break;
        }
        return result;
    };
    ProcessService.prototype.getMaxOfArray = function (numArray) {
        return Math.max.apply(null, numArray);
    };
    ProcessService.refreshList = false;
    ProcessService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProcessService_Factory() { return new ProcessService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.MatDialog), i0.ɵɵinject(i3.UiService)); }, token: ProcessService, providedIn: "root" });
    return ProcessService;
}());
export { ProcessService };
var ProcessDto = /** @class */ (function () {
    function ProcessDto() {
    }
    return ProcessDto;
}());
export { ProcessDto };
