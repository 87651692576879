import { __extends } from "tslib";
import { MatDialogRef } from '@angular/material';
import { BaseFormComponent } from 'src/app/shared/components/base/base-form.component';
var SuccessValidationMessageComponent = /** @class */ (function (_super) {
    __extends(SuccessValidationMessageComponent, _super);
    function SuccessValidationMessageComponent(dialogRef, data) {
        var _this = _super.call(this, dialogRef, data) || this;
        _this.dialogRef = dialogRef;
        _this.data = data;
        return _this;
    }
    SuccessValidationMessageComponent.prototype.create = function () {
        throw new Error('Method not implemented.');
    };
    SuccessValidationMessageComponent.prototype.edit = function () {
        throw new Error('Method not implemented.');
    };
    SuccessValidationMessageComponent.prototype.getModel = function () {
        throw new Error('Method not implemented.');
    };
    return SuccessValidationMessageComponent;
}(BaseFormComponent));
export { SuccessValidationMessageComponent };
