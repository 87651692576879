import { ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OrderTrackingDto, TrackingService } from '../services/tracking.service';
import { finalize } from 'rxjs/operators';
var OmsTrackingComponent = /** @class */ (function () {
    function OmsTrackingComponent(trackingService, activatedRoute, elementRef) {
        var _this = this;
        this.trackingService = trackingService;
        this.activatedRoute = activatedRoute;
        this.elementRef = elementRef;
        this.isLoading = false;
        this.activatedRoute.params.subscribe(function (params) {
            if (params.nro) {
                _this.numDeli = params.nro;
                _this.getDataPage();
            }
        });
    }
    OmsTrackingComponent.prototype.ngAfterViewInit = function () {
        this.elementRef
            .nativeElement.ownerDocument
            .body.style.backgroundColor = '#fff';
    };
    OmsTrackingComponent.prototype.getDataPage = function () {
        var _this = this;
        if (this.numDeli) {
            this.isLoading = true;
            this.errorMsj = '';
            this.trackingService.get(this.numDeli)
                .pipe(finalize(function () { return (_this.isLoading = false); }))
                .subscribe(function (result) {
                _this.order = result;
            }, function (error) {
                //Error callback
                // console.error('error caught in component ' + error.status);
                if (error.status == 400)
                    _this.errorMsj = 'El pedido es de retiro en tienda';
                if (error.status == 404)
                    _this.errorMsj = 'No se encuentra el pedido ingresado';
                throw error;
            });
        }
    };
    OmsTrackingComponent.prototype.openUrlZen = function () {
        var _a, _b;
        if (((_a = this.order) === null || _a === void 0 ? void 0 : _a.countryCode) == 'AR')
            window.open('https://consultaspuppisargentina.zendesk.com/hc/es-419/requests/new', '_blank');
        if (((_b = this.order) === null || _b === void 0 ? void 0 : _b.countryCode) == 'CO')
            window.open('https://consultaspuppiscolombia.zendesk.com/hc/es-419/requests/new', '_blank');
    };
    OmsTrackingComponent.prototype.isCol = function () {
        var _a;
        return ((_a = this.order) === null || _a === void 0 ? void 0 : _a.countryCode) == 'CO';
    };
    return OmsTrackingComponent;
}());
export { OmsTrackingComponent };
