import { OnChanges, OnInit } from '@angular/core';
import { navigationItems } from 'src/app/shared/models/navigation-item.model';
var SideNavComponent = /** @class */ (function () {
    function SideNavComponent(_authenticationService, _permissionService, commonService) {
        this._authenticationService = _authenticationService;
        this._permissionService = _permissionService;
        this.commonService = commonService;
        this.isExpanded = false;
        this.isShowing = false;
        this.showSubmenu = false;
        this.showSubSubMenu = false;
        this.isMobile = false;
        this.mode = 'side';
        this.countryCode = null;
        // const isMobile = window.matchMedia("only screen and (max-width: 760px)").matches;
        // is mobile
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            this.isMobile = true;
            this.mode = 'over';
            this.isShowing = true;
        }
    }
    Object.defineProperty(SideNavComponent.prototype, "navigationItems", {
        get: function () { return navigationItems; },
        enumerable: true,
        configurable: true
    });
    SideNavComponent.prototype.ngOnInit = function () {
        var _this = this;
        this._authenticationService.userProfile$.subscribe(function (profile) { return _this.user = profile; });
        this.countryCode = this.commonService.getCookie('country');
    };
    SideNavComponent.prototype.mouseenter = function () {
        if (!this.isMobile && !this.isExpanded) {
            this.isShowing = true;
        }
    };
    SideNavComponent.prototype.mouseleave = function () {
        if (!this.isMobile && !this.isExpanded) {
            this.isShowing = false;
        }
    };
    SideNavComponent.prototype.showItem = function (item) {
        var showForThisCountry = (!item.onlyForcountry) ? true : this.countryCode == item.onlyForcountry;
        return this._permissionService.checkPermissions(item.permissionName || [], "OR") && item.disabled != true && showForThisCountry;
    };
    SideNavComponent.prototype.ngOnChanges = function () {
        this.sidenav.toggle();
    };
    return SideNavComponent;
}());
export { SideNavComponent };
