import { __extends, __read } from "tslib";
import { ElementRef, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { combineLatest, Observable } from 'rxjs';
import { tap, map, finalize, startWith } from 'rxjs/operators';
import { BaseFormComponent, FormAction } from '../../../shared/components/base/base-form.component';
import { ProductLocationDto, } from 'src/app/services/wms.service';
var ProductLocationFormComponent = /** @class */ (function (_super) {
    __extends(ProductLocationFormComponent, _super);
    function ProductLocationFormComponent(productLocationService, locationService, dialogRef, productService, data) {
        var _this = _super.call(this, dialogRef, data) || this;
        _this.productLocationService = productLocationService;
        _this.locationService = locationService;
        _this.dialogRef = dialogRef;
        _this.productService = productService;
        _this.data = data;
        _this.locationControl = new FormControl();
        _this.model = new ProductLocationDto();
        return _this;
    }
    ProductLocationFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        setTimeout(function () { return _this.cancelButton.nativeElement.focus(); });
        var sku = this.data.sku;
        var countryCode = this.data.countryCode;
        var locationId = this.data.locationId;
        this.currentStore = this.data.storeCode;
        this.productService.getBySku(sku, countryCode)
            .subscribe(function (resp) { return _this.selectedProduct = resp; });
        this.isLoading = true;
        this.locations$ = this.locationService
            .getAll(countryCode, 'id asc', 0, 10000)
            .pipe(map(function (x) { return x.items; }), tap(function (items) {
            if (locationId) {
                _this.selectedLocation = items.find(function (x) { return x.id === locationId; });
                _this.locationControl.setValue(_this.selectedLocation);
            }
        }), finalize(function () { return _this.isLoading = false; }));
        this.filteredLocations = combineLatest([
            this.locations$,
            this.locationControl.valueChanges.pipe(startWith(''))
        ]).pipe(map(function (_a) {
            var _b = __read(_a, 2), locations = _b[0], value = _b[1];
            var _c;
            return _this._filter(locations, typeof value === 'string' ? value : ((_c = value) === null || _c === void 0 ? void 0 : _c.code) || '');
        }));
    };
    ;
    ProductLocationFormComponent.prototype._filter = function (locations, value) {
        var filterValue = value ? value.toLowerCase() : '';
        return locations.filter(function (location) { return location.code.toLowerCase().includes(filterValue); });
    };
    ProductLocationFormComponent.prototype.locationChange = function (event) {
        console.log('locationChange', event.option.value);
        this.selectedLocation = event.option.value;
    };
    ProductLocationFormComponent.prototype.displayLocationCode = function (location) {
        return location ? location.code : '';
    };
    ProductLocationFormComponent.prototype.enableSendBtn = function () {
        return (this.selectedLocation);
    };
    ProductLocationFormComponent.prototype.getModel = function () {
        throw new Error('Method not implemented.');
    };
    ProductLocationFormComponent.prototype.send = function () {
        var _this = this;
        this.model.storeCode = this.currentStore;
        this.model.locationId = this.selectedLocation.id;
        this.model.locationCode = this.selectedLocation.code;
        this.model.productId = this.selectedProduct.id;
        this.model.productName = this.selectedProduct.name;
        this.model.sku = this.selectedProduct.sku;
        this.model.ean = this.selectedProduct.barCode;
        this.model.imageUrl = null;
        //console.log('send', this.model);
        // crear / actulizar el wms... 
        this.isLoading = true;
        var obs = (this.action == FormAction.create) ? this.create() : this.edit();
        obs.pipe(finalize(function () { return _this.isLoading = false; }))
            .subscribe(function (result) {
            // paso el dto actualizado a la pantalla de picking y cierro modal
            _this.dialogRef.close(result);
        });
    };
    ProductLocationFormComponent.prototype.create = function () {
        return this.productLocationService.create(this.model);
    };
    ProductLocationFormComponent.prototype.edit = function () {
        return this.productLocationService.update(this.model);
    };
    return ProductLocationFormComponent;
}(BaseFormComponent));
export { ProductLocationFormComponent };
