import { BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';
import { JwksValidationHandler } from 'angular-oauth2-oidc';
import * as jwt_decode from 'jwt-decode';
import * as moment from 'moment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common/http";
import * as i3 from "angular-oauth2-oidc";
import * as i4 from "./user.service";
import * as i5 from "./common.service";
var AuthenticationService = /** @class */ (function () {
    function AuthenticationService(router, http, oauthService, userService, commonService) {
        this.router = router;
        this.http = http;
        this.oauthService = oauthService;
        this.userService = userService;
        this.commonService = commonService;
        this.endpoint = '/api/auth';
        this.isLoggedIn$ = new BehaviorSubject(false);
        this.userProfile$ = new BehaviorSubject(null);
        this.authConfig = environment.AUTH_CONFIG;
        this.baseUrl = environment.securityUrl;
        this.oauthService.configure(this.authConfig);
        this.oauthService.tokenValidationHandler = new JwksValidationHandler();
        this.isInternalUser = localStorage.getItem('is_internal_user') === 'true';
        this.getUserData(this.isInternalUser);
        // this.validateAuthorization();
    }
    AuthenticationService.prototype.loginExternal = function () {
        var body = {
            firstName: this.getFieldFromGoogle('given_name'),
            lastName: this.getFieldFromGoogle('family_name'),
            pictureUrl: this.getFieldFromGoogle('picture'),
            email: this.getFieldFromGoogle('email'),
            providerAuthentication: "google"
        };
        return this.http.post(this.baseUrl + "/login/external", body);
    };
    AuthenticationService.prototype.login = function (userInfo) {
        var body = {
            name: userInfo.email,
            password: userInfo.password
        };
        return this.http.post(this.baseUrl + "/login/internal", body);
    };
    AuthenticationService.prototype.loginWithGoogleSingin = function (login) {
        return this.http.post(this.baseUrl + "/login/google-sing-in", login);
    };
    AuthenticationService.prototype.refreshToken = function (refreshToken) {
        var httpHeaders = { refresh_token: refreshToken };
        return this.http.post(this.baseUrl + "/login/refresh-token", {}, { headers: httpHeaders });
    };
    AuthenticationService.prototype.logout = function () {
        // remove sessions
        this.userData = undefined;
        this.removeSession();
        this.isLoggedIn$.next(false);
        this.userProfile$.next(null);
        this.router.navigateByUrl('/login');
    };
    // isAuthorized() {
    //   return !!localStorage.getItem('id_token');
    // }
    AuthenticationService.prototype.validateAuthorization = function () {
        // if (this.isAuthorized()) {
        //   this.isLoggedIn$.next(true);
        //   return true;
        // } else {
        //   this.isLoggedIn$.next(false);
        //   return false;
        // }
    };
    AuthenticationService.prototype.getReturnUrl = function () {
        return localStorage.getItem('returnUrl') || false;
    };
    AuthenticationService.prototype.setReturnUrl = function (url) {
        localStorage.setItem('returnUrl', url);
    };
    AuthenticationService.prototype.removeReturnUrl = function () {
        localStorage.removeItem('returnUrl');
    };
    AuthenticationService.prototype.removeSession = function () {
        localStorage.removeItem('access_token');
        localStorage.removeItem('granted_scopes');
        localStorage.removeItem('access_token_stored_at');
        localStorage.removeItem('expires_in');
        localStorage.removeItem('id_token');
        localStorage.removeItem('id_token_claims_obj');
        localStorage.removeItem('id_token_expires_at');
        localStorage.removeItem('id_token_stored_at');
        localStorage.removeItem('session_state');
        localStorage.removeItem('is_internal_user');
        localStorage.removeItem('user');
        localStorage.removeItem('CURRENT_STORE');
        localStorage.removeItem('culture');
        this.commonService.deleteAllCookies();
        localStorage.removeItem('id_token');
        this.isLoggedIn$.next(false);
        this.userProfile$.next(null);
        this.removeReturnUrl();
    };
    AuthenticationService.prototype.isAuthenticated = function () {
        return !!localStorage.getItem('id_token') && !!localStorage.getItem('is_internal_user');
    };
    AuthenticationService.prototype.loginWithGoogle = function () {
        var _this = this;
        // this.oauthService.initImplicitFlow();
        localStorage.clear();
        localStorage.setItem('is_internal_user', 'false');
        this.setReturnUrl('/clientes');
        this.oauthService.loadDiscoveryDocument().then(function (doc) {
            _this.oauthService
                .tryLogin({
                onTokenReceived: function (context) {
                    console.log("token recibido");
                    _this.oauthService.loadUserProfile().then(function (cliams) {
                        var preUrl = _this.oauthService.state;
                        if (preUrl == null || preUrl == '') {
                            preUrl = '/';
                        }
                        _this.router.navigateByUrl(preUrl);
                    });
                }
            })
                .then(function () {
                if (!_this.oauthService.hasValidAccessToken()) {
                    var returnUrl = _this.getReturnUrl();
                    _this.removeReturnUrl();
                    _this.oauthService.initImplicitFlow(returnUrl ? returnUrl : '/oms/info');
                    localStorage.setItem('is_internal_user', 'false');
                }
            });
        });
    };
    AuthenticationService.prototype.getFieldFromGoogle = function (field) {
        var claims = this.oauthService.getIdentityClaims();
        if (!claims) {
            return null;
        }
        return claims[field];
    };
    AuthenticationService.prototype.getUserData = function (isInternalUser) {
        var user;
        if (!isInternalUser) {
            user = {
                id: '',
                password: '',
                email: this.getFieldFromGoogle('email'),
                firstName: this.getFieldFromGoogle('given_name'),
                lastName: this.getFieldFromGoogle('family_name'),
                pictureUrl: this.getFieldFromGoogle('picture')
            };
            this.userProfile$.next(user);
        }
        else {
            user = this.userService.getUserProfileFromLocal();
            if (!user || !user.id) {
                this.logout();
            }
            this.userProfile$.next(user);
        }
        return user;
    };
    AuthenticationService.prototype.setUserProfile = function (userProfile) {
        var countryCode = userProfile.countryCode;
        if (!countryCode) {
            countryCode = this.commonService.getCookie('country');
        }
        this.commonService.setCookie('country', countryCode, 120);
        this.setCountry(countryCode || 'CO');
        this.userProfile$.next(userProfile);
    };
    AuthenticationService.prototype.setCountry = function (code) {
        if (code === 'AR') {
            this.commonService.setSelectedCountry({
                code: 'AR',
                name: 'Argentina',
                icon: 'icon-flag-arg'
            });
        }
        else if (code === 'CO') {
            this.commonService.setSelectedCountry({
                code: 'CO',
                name: 'Colombia',
                icon: 'icon-flag-col'
            });
        }
        else {
            this.commonService.setSelectedCountry({
                code: '',
                name: 'Todos',
                icon: 'icon-flag-arg-col'
            });
        }
    };
    AuthenticationService.prototype.setSessionToken = function (idToken) {
        localStorage.setItem('id_token', idToken);
    };
    AuthenticationService.prototype.setRefreshToken = function (refreshToken) {
        localStorage.setItem('refresh_token', refreshToken);
    };
    AuthenticationService.prototype.getToken = function () {
        return localStorage.getItem('id_token') || false;
    };
    AuthenticationService.prototype.getRefreshToken = function () {
        return localStorage.getItem('refresh_token');
    };
    AuthenticationService.prototype.decodePayloadJWT = function () {
        try {
            if (!this.userData) {
                var userData_1 = jwt_decode(this.getToken());
                Object.keys(userData_1).forEach(function (key) {
                    if (userData_1[key] === 'True' || userData_1[key] === 'true' ||
                        userData_1[key] === 'False' || userData_1[key] === 'false') {
                        userData_1[key] = userData_1[key] === 'True' || userData_1[key] === 'true';
                    }
                });
                if (userData_1.policies) {
                    var policies_1 = [];
                    var policiesDictionary_1 = JSON.parse(userData_1.policies);
                    Object.keys(policiesDictionary_1).forEach(function (key) {
                        policiesDictionary_1[key].forEach(function (element) {
                            policies_1.push(key === element ? key : key + '.' + element);
                        });
                    });
                    userData_1.policies = policies_1;
                }
                this.userData = userData_1;
            }
        }
        catch (Error) {
            this.logout();
        }
        return this.userData;
    };
    AuthenticationService.prototype.hasPolicy = function (policy) {
        var userData = this.decodePayloadJWT();
        if (userData && userData.policies) {
            return userData.policies.indexOf(policy) >= 0;
        }
        return false;
    };
    AuthenticationService.prototype.isInRole = function (role) {
        var userData = this.decodePayloadJWT();
        if (userData && userData.roles) {
            var roles = JSON.parse(userData.roles);
            var hasRol = roles.find(function (r) { return r.Name === role; });
            return (hasRol) ? true : false;
        }
        return false;
    };
    AuthenticationService.prototype.isAdmin = function () {
        var userData = this.decodePayloadJWT();
        return userData && userData.isAdmin;
    };
    AuthenticationService.prototype.isStoreUser = function () {
        return this.userProfile$.value.storeCode !== '' && this.userProfile$.value.storeCode !== null;
    };
    AuthenticationService.prototype.setExpirationDate = function (time) {
        var today = moment();
        var expiration = moment(today).add(time, 'seconds');
        localStorage.setItem('expires_in', moment.utc(expiration).format());
    };
    AuthenticationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthenticationService_Factory() { return new AuthenticationService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.OAuthService), i0.ɵɵinject(i4.UserService), i0.ɵɵinject(i5.CommonService)); }, token: AuthenticationService, providedIn: "root" });
    return AuthenticationService;
}());
export { AuthenticationService };
