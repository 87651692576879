import { HttpParams } from '@angular/common/http';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./common.service";
var UserService = /** @class */ (function () {
    function UserService(http, commonService) {
        this.http = http;
        this.commonService = commonService;
        this._users = [];
        this.COUNTRY_KEY = 'country';
        this.baseUrl = environment.securityUrl;
    }
    UserService.prototype.getUserProfileFromLocal = function () {
        var user = JSON.parse(localStorage.getItem('user'));
        return user;
    };
    UserService.prototype.getUserList = function (searchData) {
        return this.http.get(this.baseUrl + "/users", {
            params: searchData
        });
    };
    UserService.prototype.createUser = function (user) {
        var userDto = {
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            pictureUrl: user.pictureUrl,
            isAdmin: user.isAdmin,
            storeCode: user.storeCode,
            countryCode: user.countryCode,
            password: user.password,
            rolesIds: user.rolesId ? user.rolesId : []
        };
        return this.http.post(this.baseUrl + "/user/create", userDto);
    };
    UserService.prototype.updateUser = function (user) {
        var userDto = {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            pictureUrl: user.pictureUrl,
            isAdmin: user.isAdmin,
            storeCode: user.storeCode,
            countryCode: user.countryCode,
            password: user.password,
            rolesId: user.rolesId ? user.rolesId : []
        };
        return this.http.post(this.baseUrl + "/user/update/" + user.id, userDto);
    };
    UserService.prototype.changeUserStatus = function (user) {
        return this.http.post(this.baseUrl + "/user/setDisabled/" + user.id, user.isDisabled, {
            headers: {
                content: 'application/json',
                'content-type': 'application/json'
            }
        });
    };
    /** user set the password from register page */
    UserService.prototype.resetPassword = function (id, password, passwordConfirmed) {
        var passwordDto = {
            password: password,
            passwordConfirmed: passwordConfirmed
        };
        return this.http.post(this.baseUrl + "/user/resetPassword/" + id, passwordDto);
    };
    /** Admin clicks on reset user password */
    UserService.prototype.setResetPassword = function (id, email) {
        var params = new HttpParams();
        if (id) {
            params = params.append('id', id);
        }
        if (email) {
            params = params.append('email', email);
        }
        return this.http.get(this.baseUrl + "/user/setResetPassword", { params: params });
    };
    /** usuario enter from registration page */
    UserService.prototype.getUserToRegister = function (token) {
        return this.http.get(this.baseUrl + "/user/confirmRegister/" + token);
    };
    UserService.prototype.getUserToResetPassword = function (token) {
        return this.http.get(this.baseUrl + "/user/confirmResetPassword/" + token);
    };
    UserService.prototype.load = function () {
        var _this = this;
        return this.http
            .get(this.baseUrl + "/Users/GetCatalog")
            .pipe(catchError(function (e) { return of([]); }))
            .pipe(tap(function (users) {
            _this._users = users;
        }));
    };
    UserService.prototype.getUsersCatalog = function () {
        return this._users;
    };
    UserService.prototype.getUsersCataloByCountry = function (country) {
        return this._users.filter(function (u) { return u.countryCode === country || !u.countryCode; });
    };
    UserService.prototype.getStoreUsersCatalog = function (storeCode) {
        return this.getUsersCatalog().filter(function (x) { return x.storeCode == storeCode; });
    };
    UserService.prototype.getUserName = function (id) {
        var user = this.getUsersCatalog().find(function (x) { return x.id == id; });
        if (user)
            return user.firstName + " " + user.lastName;
        else
            return id;
    };
    UserService.prototype.isNotUserWithFixedStore = function () {
        var user = this.getUserProfileFromLocal().id;
        return !this.getUsersCatalog().find(function (x) { return x.id == user; }).storeCode;
    };
    UserService.prototype.getCsv = function (searchData) {
        return this.http.get(this.baseUrl + "/users/getcsv", {
            params: searchData
        });
    };
    UserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserService_Factory() { return new UserService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.CommonService)); }, token: UserService, providedIn: "root" });
    return UserService;
}());
export { UserService };
