import { OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialogConfig } from '@angular/material';
import { PasswordStrengthValidator } from '../shared/validators/password-strength.validators';
import { ConfirmationDialogComponent } from '../shared/components/confirmation-dialog/confirmation-dialog.component';
var CrossFieldErrorMatcher = /** @class */ (function () {
    function CrossFieldErrorMatcher() {
    }
    CrossFieldErrorMatcher.prototype.isErrorState = function (control, form) {
        return control.dirty && form.invalid;
    };
    return CrossFieldErrorMatcher;
}());
var RegistrationComponent = /** @class */ (function () {
    function RegistrationComponent(route, dialog, formBuilder, userService, router) {
        this.route = route;
        this.dialog = dialog;
        this.formBuilder = formBuilder;
        this.userService = userService;
        this.router = router;
        this.isSubmitted = false;
        this.passwordStrengthText = 'Débil';
        this.userCode = this.route.snapshot.paramMap.get('userCode');
        this.action = this.route.snapshot.paramMap.get('action');
        this.initForm();
    }
    RegistrationComponent.prototype.initForm = function () {
        this.registerForm = this.formBuilder.group({
            password1: ['', [Validators.required, PasswordStrengthValidator]],
            password2: ['', Validators.required]
        }, {
            validator: this.passwordValidator
        });
    };
    Object.defineProperty(RegistrationComponent.prototype, "formControls", {
        get: function () {
            return this.registerForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    RegistrationComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.action === 'newuser') {
            this.userService.getUserToRegister(this.userCode).subscribe(function (response) {
                _this.user = response.data;
            }, function (error) {
                _this.errorMessage = 'Usuario no encontrado';
            });
        }
        if (this.action === 'resetpassword') {
            this.userService.getUserToResetPassword(this.userCode).subscribe(function (response) {
                _this.user = response.data;
            }, function (error) {
                _this.errorMessage = 'Usuario no encontrado';
            });
        }
        if (this.action === 'emailconfirm') {
            this.userService.getUserToRegister(this.userCode).subscribe(function (response) {
                _this.router.navigate(['/login']);
            }, function (error) {
                alert('Usuario no encontrado');
                _this.router.navigate(['/login']);
            });
        }
    };
    RegistrationComponent.prototype.passwordValidator = function (form) {
        var condition = form.get('password1').value !== form.get('password2').value;
        return condition ? { passwordsDoNotMatch: true } : null;
    };
    RegistrationComponent.prototype.onStrengthChanged = function (strength) {
        if (strength === 100) {
            this.passwordStrengthText = 'Segura';
        }
        else if (strength >= 40 && strength < 100) {
            this.passwordStrengthText = 'Moderada';
        }
        else {
            this.passwordStrengthText = 'Débil';
        }
    };
    RegistrationComponent.prototype.getDialogConfig = function () {
        var disableUserdialogConfig = new MatDialogConfig();
        disableUserdialogConfig.disableClose = false;
        disableUserdialogConfig.autoFocus = false;
        disableUserdialogConfig.data = {
            title: '¡Su contraseña ha sido creada con éxito!',
            text: 'Debe ingresar sus datos para poder iniciar sesión',
            btnPrimaryText: 'Aceptar',
            hideCancelButton: true
        };
        return disableUserdialogConfig;
    };
    RegistrationComponent.prototype.setPassword = function () {
        var _this = this;
        this.isSubmitted = true;
        if (!this.registerForm.invalid) {
            this.userService
                .resetPassword(this.user.id, this.registerForm.controls['password1'].value, this.registerForm.controls['password2'].value)
                .subscribe(function (response) {
                if (response.status) {
                    _this.openConfirmationDialog();
                }
            }, function (error) {
                _this.errorMessage = "Surgió un error al establecer la contraseña.";
                alert(_this.errorMessage);
            });
        }
    };
    RegistrationComponent.prototype.openConfirmationDialog = function () {
        var _this = this;
        var dialogConfig = this.getDialogConfig();
        var dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(function (responseDialog) {
            if (responseDialog === 'Confirm') {
                _this.router.navigateByUrl('/login');
            }
        });
    };
    return RegistrationComponent;
}());
export { RegistrationComponent };
