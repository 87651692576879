import * as i0 from "@angular/core";
var BeepService = /** @class */ (function () {
    function BeepService() {
    }
    BeepService.prototype.beep = function () {
        var audio = new Audio();
        audio.src = 'assets/sounds/beep.wav';
        audio.load();
        audio.play();
    };
    BeepService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BeepService_Factory() { return new BeepService(); }, token: BeepService, providedIn: "root" });
    return BeepService;
}());
export { BeepService };
