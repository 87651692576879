import { __assign } from "tslib";
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var TrackingService = /** @class */ (function () {
    function TrackingService(http) {
        this.http = http;
        this.baseUrl = environment.omsUrl;
    }
    TrackingService.prototype.get = function (nro) {
        var queryUrl = this.baseUrl + "/api/services/app/OrderTracking/Get?nro=" + nro;
        return this.http
            .get(queryUrl)
            .pipe(map(function (x) { return x.result ? x.result : null; }));
    };
    TrackingService.prototype.validateOrderBySecureId = function (secureId) {
        var query = this.baseUrl + "/api/services/app/OrderTracking/ValidateOrderBySecureId?secureid=" + secureId;
        return this.http.get(query).pipe(map(function (x) { return x.result ? x.result : null; }));
    };
    TrackingService.prototype.updateDeliveryData = function (deliveryData) {
        var query = this.baseUrl + "/api/services/app/OrderTracking/UpdateDeliveryData";
        return this.http.post(query, __assign({}, deliveryData));
    };
    TrackingService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TrackingService_Factory() { return new TrackingService(i0.ɵɵinject(i1.HttpClient)); }, token: TrackingService, providedIn: "root" });
    return TrackingService;
}());
export { TrackingService };
var OrderTrackingDto = /** @class */ (function () {
    function OrderTrackingDto() {
    }
    return OrderTrackingDto;
}());
export { OrderTrackingDto };
var OrderTrackingStepDto = /** @class */ (function () {
    function OrderTrackingStepDto() {
    }
    return OrderTrackingStepDto;
}());
export { OrderTrackingStepDto };
