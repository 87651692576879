import { __values } from "tslib";
import * as i0 from "@angular/core";
import * as i1 from "./authentication.service";
var PermissionService = /** @class */ (function () {
    function PermissionService(authenticationService) {
        this.authenticationService = authenticationService;
    }
    PermissionService.prototype.checkPermission = function (permission) {
        return this.checkPermissions([permission]);
    };
    PermissionService.prototype.checkPermissions = function (permissions, logicalOp) {
        var e_1, _a;
        if (logicalOp === void 0) { logicalOp = 'AND'; }
        var hasPermission = false;
        if (this.authenticationService.isAdmin()) {
            return true;
        }
        try {
            for (var permissions_1 = __values(permissions), permissions_1_1 = permissions_1.next(); !permissions_1_1.done; permissions_1_1 = permissions_1.next()) {
                var checkPermission = permissions_1_1.value;
                if (this.authenticationService.hasPolicy(checkPermission)) {
                    hasPermission = true;
                    if (logicalOp === 'OR') {
                        break;
                    }
                }
                else {
                    hasPermission = false;
                    if (logicalOp === 'AND') {
                        break;
                    }
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (permissions_1_1 && !permissions_1_1.done && (_a = permissions_1.return)) _a.call(permissions_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return hasPermission;
    };
    PermissionService.prototype.checkRole = function (role) {
        return this.checkRoles([role]);
    };
    PermissionService.prototype.checkRoles = function (roles, logicalOp) {
        var e_2, _a;
        if (logicalOp === void 0) { logicalOp = 'AND'; }
        var hasRole = false;
        if (this.authenticationService.isAdmin()) {
            return true;
        }
        try {
            for (var roles_1 = __values(roles), roles_1_1 = roles_1.next(); !roles_1_1.done; roles_1_1 = roles_1.next()) {
                var role = roles_1_1.value;
                if (this.authenticationService.isInRole(role)) {
                    hasRole = true;
                    if (logicalOp === 'OR') {
                        break;
                    }
                }
                else {
                    hasRole = false;
                    if (logicalOp === 'AND') {
                        break;
                    }
                }
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (roles_1_1 && !roles_1_1.done && (_a = roles_1.return)) _a.call(roles_1);
            }
            finally { if (e_2) throw e_2.error; }
        }
        return hasRole;
    };
    PermissionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PermissionService_Factory() { return new PermissionService(i0.ɵɵinject(i1.AuthenticationService)); }, token: PermissionService, providedIn: "root" });
    return PermissionService;
}());
export { PermissionService };
