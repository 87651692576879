import { NotificationResponse, NotificationServiceProxy } from '../notifications/services/service-proxy/service-proxies';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, timer } from 'rxjs';
import { concatMap, map, shareReplay } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var NotificationsService = /** @class */ (function () {
    function NotificationsService(http) {
        this.http = http;
        this.url = environment.notificationsUrl + "/api/services/app/Notification/getAllByCurrentUser?channel=APP";
        this.getAllNotifications();
    }
    NotificationsService.prototype.getAllNotifications = function () {
        this.proxy = new NotificationServiceProxy(this.http, environment.notificationsUrl);
        this.setTimer();
    };
    NotificationsService.prototype.setTimer = function () {
        var observ$ = this.http.get(this.url)
            .pipe(map(function (x) { return x.result; }));
        this.notifications$ = timer(0, 60000).pipe(concatMap(function (_) {
            return observ$;
        }), shareReplay(1));
    };
    NotificationsService.prototype.markAsReaded = function (notificationIds) {
        return this.proxy.markNotificationAsReaded(notificationIds);
    };
    NotificationsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NotificationsService_Factory() { return new NotificationsService(i0.ɵɵinject(i1.HttpClient)); }, token: NotificationsService, providedIn: "root" });
    return NotificationsService;
}());
export { NotificationsService };
