import { AfterViewInit, ChangeDetectorRef } from '@angular/core';
import Quagga from '@ericblade/quagga2';
import { getMainBarcodeScanningCamera } from './camera-access';
var EanScanComponent = /** @class */ (function () {
    function EanScanComponent(beepService, 
    // private updateService: UpdateService,
    changeDetectorRef, dialogRef) {
        this.beepService = beepService;
        this.changeDetectorRef = changeDetectorRef;
        this.dialogRef = dialogRef;
    }
    EanScanComponent.prototype.ngAfterViewInit = function () {
        if (!navigator.mediaDevices || !(typeof navigator.mediaDevices.getUserMedia === 'function')) {
            this.errorMessage = 'getUserMedia is not supported';
            return;
        }
        this.initializeScanner();
        this.dialogRef.afterClosed().subscribe(function () { return Quagga.stop(); });
        /*if (environment.production) {
          setTimeout(() => {
            this.updateService.checkForUpdates();
          }, 10000);
        }*/
    };
    EanScanComponent.prototype.initializeScanner = function () {
        var _this = this;
        if (!navigator.mediaDevices || !(typeof navigator.mediaDevices.getUserMedia === 'function')) {
            this.errorMessage =
                'getUserMedia is not supported. Please use Chrome on Android or Safari on iOS';
            this.started = false;
            return Promise.reject(this.errorMessage);
        }
        // enumerate devices and do some heuristics to find a suitable first camera
        return Quagga.CameraAccess.enumerateVideoDevices()
            .then(function (mediaDeviceInfos) {
            var mainCamera = getMainBarcodeScanningCamera(mediaDeviceInfos);
            if (mainCamera) {
                console.log("Using " + mainCamera.label + " (" + mainCamera.deviceId + ") as initial camera");
                return _this.initializeScannerWithDevice(mainCamera.deviceId);
            }
            else {
                console.error("Unable to determine suitable camera, will fall back to default handling");
                return _this.initializeScannerWithDevice(undefined);
            }
        })
            .catch(function (error) {
            _this.errorMessage = "Failed to enumerate devices: " + error;
            _this.started = false;
        });
    };
    EanScanComponent.prototype.initializeScannerWithDevice = function (preferredDeviceId) {
        var _this = this;
        var _a;
        console.log("Initializing Quagga scanner...");
        var constraints = {};
        if (preferredDeviceId) {
            // if we have a specific device, we select that
            constraints.deviceId = preferredDeviceId;
        }
        else {
            // otherwise we tell the browser we want a camera facing backwards (note that browser does not always care about this)
            constraints.facingMode = 'environment';
        }
        return Quagga.init({
            inputStream: {
                type: 'LiveStream',
                constraints: constraints,
                area: {
                    // defines rectangle of the detection/localization area
                    top: '25%',
                    right: '10%',
                    left: '10%',
                    bottom: '25%',
                },
                target: (_a = document.querySelector('#scanner-container'), (_a !== null && _a !== void 0 ? _a : undefined)),
            },
            decoder: {
                readers: ['ean_reader', 'upc_reader', 'ean_8_reader'],
                multiple: false,
            },
            // See: https://github.com/ericblade/quagga2/blob/master/README.md#locate
            locate: false,
        }, function (err) {
            if (err) {
                console.error("Quagga initialization failed: " + err);
                _this.errorMessage = "Initialization error: " + err;
                _this.started = false;
            }
            else {
                console.log("Quagga initialization succeeded");
                Quagga.start();
                _this.started = true;
                _this.changeDetectorRef.detectChanges();
                Quagga.onDetected(function (res) {
                    if (res.codeResult.code) {
                        //var msj = res.codeResult.format + ' - ' + res.codeResult.code;
                        _this.onBarcodeScanned(res.codeResult.code);
                        //alert(msj);
                    }
                });
            }
        });
    };
    EanScanComponent.prototype.onBarcodeScanned = function (code) {
        // ignore duplicates for 2 seconds
        var now = new Date().getTime();
        if (this.lastScannedCodeDate !== undefined) {
            if (code === this.lastScannedCode && now < this.lastScannedCodeDate + 2000) {
                return;
            }
        }
        this.lastScannedCode = code;
        this.lastScannedCodeDate = now;
        this.changeDetectorRef.detectChanges();
        this.beepService.beep();
        this.dialogRef.close(code);
    };
    return EanScanComponent;
}());
export { EanScanComponent };
