import { Routes } from '@angular/router';
import { DataValidationLandingComponent } from './data-validation-landing/data-validation-landing.component';
import { AuthGuard } from './guards/auth.guard';
import { LoginComponent } from './login/login.component';
import { PickingMobileFormComponent } from './oms/picking-groups/picking-mobile-form.component';
import { RegistrationComponent } from './registration/registration.component';
import { OmsTrackingComponent } from './tracking/tracking.component';
import { TransferQRComponent } from './transfers/public/transfer-qr/transfer-qr.component';
var ɵ0 = function () { return import("./home/home.module.ngfactory").then(function (m) { return m.HomeModuleNgFactory; }); };
var routes = [
    { path: 'login', component: LoginComponent },
    { path: 'registro/:action/:userCode', component: RegistrationComponent },
    { path: 'public/tracking/:nro', component: OmsTrackingComponent },
    { path: 'public/tracking', component: OmsTrackingComponent },
    { path: 'public/validate/:token', component: DataValidationLandingComponent },
    { path: 'public/transfer-qr/:id', component: TransferQRComponent },
    { path: 'separation/:id', canActivate: [AuthGuard], component: PickingMobileFormComponent },
    {
        path: '**',
        canActivate: [AuthGuard],
        loadChildren: ɵ0,
    },
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0 };
