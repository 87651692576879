import { OnInit } from '@angular/core';
import { MatDialogConfig } from '@angular/material';
import { tap, concatMap, finalize } from 'rxjs/operators';
import { EanScanComponent } from 'src/app/products-lite/components/ean-scan/ean-scan.component';
import { getRemoteServiceBaseUrl } from '../oms.module';
import { PickingGroupDto, PickingGroupItemDto, PickingGroupItemServiceProxy, PickingGroupServiceProxy, UpdatePickedQuantityRequest, } from '../services/service-proxy/service-proxies';
import { ProductLocationFormComponent } from './product-location-form/product-location-form.component';
var ɵ0 = getRemoteServiceBaseUrl;
var PickingMobileFormComponent = /** @class */ (function () {
    function PickingMobileFormComponent(pickingGroupService, pickingGroupItemServiceProxy, activatedRoute, uiService, dialog, _storeService) {
        this.pickingGroupService = pickingGroupService;
        this.pickingGroupItemServiceProxy = pickingGroupItemServiceProxy;
        this.activatedRoute = activatedRoute;
        this.uiService = uiService;
        this.dialog = dialog;
        this._storeService = _storeService;
        this.isLoading = false;
        this.finished = false;
        this.countryCode = 'CO';
    }
    PickingMobileFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.uiService.openSnackBar('Listo para pickear', null);
        this.currentProductNumber = 1;
        this.pickingGroup$ = this.activatedRoute.params.pipe(concatMap(function (params) { return _this.pickingGroupService.getWithOrders(params.id); }), tap(function (data) {
            console.log('pickingGroupService ', data);
            _this.setCountryCodeByStore(data.storeCode);
        }));
    };
    PickingMobileFormComponent.prototype.setCountryCodeByStore = function (storeCode) {
        var store = this._storeService.getStore(storeCode);
        if (store) {
            this.countryCode = store.countryCode;
            this.storeCode = store.storeCode;
        }
    };
    PickingMobileFormComponent.prototype.onBarcodeScanned = function (item) {
        if (item.quantityPicked < item.quantity) {
            var pickedOk = false;
            if (this.barcode === item.barCode) {
                item.quantityPicked++;
                pickedOk = true;
            }
            else {
                //  fix para codigos de 12 digitos que el lector EAN13 los puede leer con un 0 adelante
                if ((item.barCode.length == 12 && this.barcode.length == 13 && this.barcode.charAt(0) === '0')) {
                    this.barcode = this.barcode.substring(1);
                    if (this.barcode === item.barCode) {
                        item.quantityPicked++;
                        pickedOk = true;
                    }
                }
            }
            if (!pickedOk)
                this.uiService.openSnackBar('El codigo escaneado no corresponde al producto.', null);
            return;
        }
    };
    PickingMobileFormComponent.prototype.nextProduct = function (picking) {
        var product = picking.pickingGroupItems[this.currentProductNumber - 1];
        if (product.quantityPicked > product.quantity) {
            this.uiService.openSnackBar('La cantidad pickeada excede al total.', null);
        }
        else if (this.currentProductNumber < picking.pickingGroupItems.length) {
            this.barcode = '';
            this.currentProductNumber++;
        }
    };
    PickingMobileFormComponent.prototype.prevProduct = function () {
        if (this.currentProductNumber > 1) {
            this.barcode = '';
            this.currentProductNumber--;
        }
    };
    PickingMobileFormComponent.prototype.finishPicking = function (picking) {
        var _this = this;
        if (picking.pickingGroupItems.some(function (x) { return x.quantityPicked > x.quantity; })) {
            this.uiService.openSnackBar('La cantidad pickeada excede al total.', null);
            return;
        }
        this.isLoading = true;
        var request = new UpdatePickedQuantityRequest({
            pickingGroupId: picking.id,
            pickingGroupItems: picking.pickingGroupItems,
        });
        this.pickingGroupService
            .updatePickedQuantity(request)
            .pipe(finalize(function () { return (_this.isLoading = false); }))
            .subscribe(function () {
            _this.finished = true;
        });
    };
    PickingMobileFormComponent.prototype.openScan = function (item) {
        var _this = this;
        var dialogConfig = new MatDialogConfig();
        // dialogConfig.data = data;
        dialogConfig.width = '95%';
        dialogConfig.maxWidth = '95%';
        dialogConfig.height = '300px';
        dialogConfig.panelClass = 'scan-dialog-container';
        this.dialog.open(EanScanComponent, dialogConfig)
            .afterClosed()
            .subscribe(function (codeResult) {
            if (codeResult) {
                _this.barcode = codeResult;
                _this.onBarcodeScanned(item);
            }
        });
    };
    PickingMobileFormComponent.prototype.selectLocation = function (pgItem) {
        //  Aislee > Location > Prod
        var _this = this;
        var dialogConfig = new MatDialogConfig();
        dialogConfig.data =
            {
                id: pgItem.locationId,
                locationId: pgItem.locationId,
                countryCode: this.countryCode,
                storeCode: this.storeCode,
                sku: pgItem.sku
            };
        this.dialog.open(ProductLocationFormComponent, dialogConfig)
            .afterClosed()
            .subscribe(function (updatedProductLocation) {
            if (updatedProductLocation) {
                console.log('updated ProductLocation in WMS', updatedProductLocation);
                _this.isLoading = true;
                // al actulizar el dato de productLocation en WMS, actualizo el pickingGroupItem de la pantalla para refrescar la vista
                pgItem.locationId = updatedProductLocation.locationId;
                pgItem.location = updatedProductLocation.locationCode;
                pgItem.aisle = updatedProductLocation.aisleName;
                // actulizar el pickingGroupItem en el OMS
                // Problema
                // ( y si se cambian los productos de location en el wms, los picking group quedan desactulizados ( exepto este)
                // Solucion,  si actulizo un ProductLocation, deberia buscar todos los pickingGroups activos que tengan ese producto y actulizarlos)
                _this.pickingGroupItemServiceProxy.update(pgItem)
                    .pipe(finalize(function () { return _this.isLoading = false; }))
                    .subscribe(function (updatedItem) { console.log('updated Item in oms', updatedItem); });
            }
        });
    };
    return PickingMobileFormComponent;
}());
export { PickingMobileFormComponent };
export { ɵ0 };
