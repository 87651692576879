import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Subject } from 'rxjs';
import * as moment from 'moment';
import { HelperService } from '../oms/services/helper.service';
import { tap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var CommonService = /** @class */ (function () {
    // documentTypeList$ = new BehaviorSubject(null); 
    function CommonService(http) {
        this.http = http;
        this.COUNTRY_KEY = 'country';
        this.selectedCountry$ = new Subject();
        this.countryList$ = new BehaviorSubject(null);
        this.countryListCities$ = new BehaviorSubject(null);
        this.clientUrl = environment.clientUrl;
    }
    CommonService.prototype.getCountryCode = function () {
        return this.getCookie(this.COUNTRY_KEY);
    };
    CommonService.prototype.setSelectedCountry = function (country) {
        HelperService.storeOnLocalStorage('CURRENT_STORE', '');
        this.selectedCountry$.next(country);
    };
    CommonService.prototype.getCountryList = function () {
        var _this = this;
        return this.http.get(this.clientUrl + "/country").pipe(tap(function (countries) { return _this.countryList$.next(countries); }));
    };
    CommonService.prototype.getCountryById = function (id) {
        return this.http.get(this.clientUrl + "/country/" + id);
    };
    CommonService.prototype.getStatesByCountryId = function (id) {
        return this.http.get(this.clientUrl + "/State/statesByCountry/" + id);
    };
    CommonService.prototype.getStatesById = function (id) {
        return this.http.get(this.clientUrl + "/State/" + id);
    };
    CommonService.prototype.getCityByStateId = function (id) {
        return this.http.get(this.clientUrl + "/City/citiesByState/" + id);
    };
    CommonService.prototype.getCityById = function (id) {
        return this.http.get(this.clientUrl + "/City/" + id);
    };
    CommonService.prototype.getGenderList = function () {
        return this.http.get(this.clientUrl + "/Client/genders");
    };
    //"{Codes}/Cities"
    CommonService.prototype.getCountriesWithStatesAndCities = function (countryCodes) {
        var _this = this;
        return this.http.post(this.clientUrl + "/Country/Cities", countryCodes).pipe(tap(function (countries) { return _this.countryListCities$.next(countries); }));
    };
    CommonService.prototype.getCountriesFromLocalStorage = function () {
        var countryStatesAndCity = JSON.parse(localStorage.getItem('CountriesStatesCities'));
        return countryStatesAndCity;
    };
    CommonService.prototype.getFilteredCountryByCityIdFromLocal = function (cityId) {
        var countryStatesAndCity = JSON.parse(localStorage.getItem('CountriesStatesCities'));
        return countryStatesAndCity.filter(function (country) {
            return country.states.some(function (state) { return state.cities.some(function (city) { return city.id === cityId; }); });
        });
    };
    CommonService.prototype.getDifferenceInDays = function (biggerDate, smallerDate) {
        return Math.round(moment.duration(moment(biggerDate).diff(moment(smallerDate))).asDays());
    };
    CommonService.prototype.getAllCountryListFromLocal = function () {
        return JSON.parse(localStorage.getItem('allCountryList'));
    };
    CommonService.prototype.getDocumentTypeListFromLocal = function () {
        return JSON.parse(localStorage.getItem('documentTypeList'));
    };
    CommonService.prototype.getGenderListFromLocal = function () {
        return JSON.parse(localStorage.getItem('genderList'));
    };
    CommonService.prototype.getAddressTypeListFromLocal = function () {
        return JSON.parse(localStorage.getItem('addressTypeList'));
    };
    CommonService.prototype.setCookie = function (cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = 'expires=' + d.toUTCString();
        var cookie = cname + '=' + cvalue + ';' + expires + ';SameSite=Lax;path=/';
        document.cookie = cookie;
    };
    CommonService.prototype.getCookie = function (cname) {
        var name = cname + '=';
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return '';
    };
    CommonService.prototype.clearCookie = function (cname) {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i];
            var eqPos = cookie.indexOf('=');
            var name_1 = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = cname || name_1 + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
        }
    };
    CommonService.prototype.deleteAllCookies = function () {
        var cookies = document.cookie.split(";");
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i];
            var eqPos = cookie.indexOf("=");
            var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }
    };
    CommonService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CommonService_Factory() { return new CommonService(i0.ɵɵinject(i1.HttpClient)); }, token: CommonService, providedIn: "root" });
    return CommonService;
}());
export { CommonService };
